exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chipkarta-js": () => import("./../../../src/pages/chipkarta.js" /* webpackChunkName: "component---src-pages-chipkarta-js" */),
  "component---src-pages-dodaty-kompaniyu-js": () => import("./../../../src/pages/dodaty-kompaniyu.js" /* webpackChunkName: "component---src-pages-dodaty-kompaniyu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakty-js": () => import("./../../../src/pages/kontakty.js" /* webpackChunkName: "component---src-pages-kontakty-js" */),
  "component---src-pages-licenziya-vodiya-mizhnarodnika-js": () => import("./../../../src/pages/licenziya-vodiya-mizhnarodnika.js" /* webpackChunkName: "component---src-pages-licenziya-vodiya-mizhnarodnika-js" */),
  "component---src-pages-rules-js": () => import("./../../../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-city-js": () => import("./../../../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-companies-list-js": () => import("./../../../src/templates/companiesList.js" /* webpackChunkName: "component---src-templates-companies-list-js" */),
  "component---src-templates-company-js": () => import("./../../../src/templates/company.js" /* webpackChunkName: "component---src-templates-company-js" */),
  "component---src-templates-oblast-js": () => import("./../../../src/templates/oblast.js" /* webpackChunkName: "component---src-templates-oblast-js" */)
}

